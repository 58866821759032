import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {SubscriptionItem} from '../types';

export const columns = [
    {
        title: I18n.get('Subscription Item'),
        width: '1fr',
        render: (item: SubscriptionItem) => <NavLink to={`/subscription-items/${item.id}`}>{item.id}</NavLink>,
    },
    {
        title: I18n.get('Account Holder'),
        width: '1fr',
        render: (item: SubscriptionItem) => item.account_holder_name,
    },
    {
        title: I18n.get('Bank'),
        width: '1fr',
        render: (item: SubscriptionItem) => item.bank_name,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: SubscriptionItem) => formatTimestamp(item.created as string),
    },
];
