import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@flexinet/ui-components';

import {Card} from '../types';
import {useNavigate} from "react-router-dom";
import {stage} from "../../../config/stage";
import {
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {
    CreateTokenCardData,
    StripeAddressElement
} from "@stripe/stripe-js";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<Card, 'id'>,
    handleSubmit: (values: Card) => void
}) => {
    const navigate = useNavigate()
    const stripe = useStripe();
    const elements = useElements();


    const onSubmit = async () => {

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const cardNumberElement = elements.getElement('cardNumber')
        if (!cardNumberElement) {
            throw new Error('card number element is required parameter')
        }

        // const expirationDateElement = elements.getElement('cardExpiry')
        // const cvcElement = elements.getElement('cardCvc')


        const addressElement = elements.getElement('address') as StripeAddressElement

        const addressElementValue = await addressElement.getValue()
        console.debug('addressElementValue', addressElementValue)

        const result = await stripe.createToken(cardNumberElement, {
            name: addressElementValue.value.name?.toString(),

            address_line1: addressElementValue.value.address.line1?.toString(),
            address_line2: addressElementValue?.value.address.line2?.toString(),
            address_city: addressElementValue?.value.address.city?.toString(),
            address_state: addressElementValue?.value.address.state?.toString(),
            address_zip: addressElementValue?.value.address.postal_code?.toString(),
            address_country: addressElementValue?.value.address.country?.toString(),

        } as CreateTokenCardData);

        if (typeof result.token === 'undefined') {
            throw new Error('token is required parameter')
        }

        const payload = {
            source: result.token.id,
        } as Card

        handleSubmit(payload);
        navigate(`/customers/${record.customer}`);
    };

    const onCancel = () => {
        navigate(`/customers/${record.customer}`);
    };


    let initialValues = {} as Card;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting}
                                 handleCancel={onCancel} debug={true && stage === 'ide'} {...rest}  >
                    <FormComponent/>
                </FormWithButtons>)}
        </Formik>
    );
};
