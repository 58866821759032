import {I18n} from '@aws-amplify/core';

import {FlexiAddButton,} from '@flexinet/ui-components';

import {Title,} from '@mantine/core';

import {
    useNavigate,
    useParams
} from 'react-router-dom';
import {useBankAccount} from "../hooks/useBankAccount.ts";


import {openModal,} from '@mantine/modals';


import {RecordForm} from "../forms/RecordForm.tsx";


export const AddRecordButton = ({
                                    buttonText = 'Add Bank Account',
                                    size = 'sm',
                                    onClick,
                                }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }


    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate(`/customers/${customerId}/bank-accounts/add`);
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};


export const AddRecordButtonNew = () => {


    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter');
    }

    const {createRecord} = useBankAccount(customerId);


    const onClickHandler = () => openModal({
                                               title: <Title order={3}>Add Bank Account</Title>,
                                               children: (
                                                   <RecordForm
                                                       handleSubmit={(values) => {
                                                           createRecord(values);
                                                           //closeAllModals();
                                                       }}
                                                       record={{
                                                           customer: customerId,
                                                       }}
                                                   />
                                               ),
                                           });


    return (
        <FlexiAddButton onClick={onClickHandler}>Add Bank Account</FlexiAddButton>
    );
};

