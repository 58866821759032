export const createInvoice = `
    mutation createInvoice($input: CreateInvoiceInput!) {
        createInvoice(input:$input) {
            id
            amountDue
            amountPaid
            currency
            customer
            customerName
            customerEmail
            description
            number
            paid
            periodStart
            periodEnd
            status
            total
            hostedInvoiceUrl
            invoicePdf
            created
    }
}`;

export const updateInvoice = `
    mutation updateInvoice($input: UpdateInvoiceInput!) {
        updateInvoice(input:$input){
            id
            amountDue
            amountPaid
            currency
            customer
            customerName
            customerEmail
            description
            number
            paid
            periodStart
            periodEnd
            status
            total
            hostedInvoiceUrl
            invoicePdf
            created
        }
}`;

export const deleteInvoice = `
    mutation deleteInvoice($input: DeleteInvoiceInput!) {
        deleteInvoice(input:$input) 
}`;

export const sendInvoice = `
    mutation sendInvoice($input: SendInvoiceInput!) {
        sendInvoice(input:$input) {
            id
            amountDue
            amountPaid
            currency
            customer
            customerName
            customerEmail
            description
            number
            paid
            periodStart
            periodEnd
            status
            total
            hostedInvoiceUrl
            invoicePdf
            created
        }
}`;

export const voidInvoice = `
    mutation voidInvoice($input: VoidInvoiceInput!) {
        voidInvoice(input:$input) {
            id
            amountDue
            amountPaid
            currency
            customer
            customerName
            customerEmail
            description
            number
            paid
            periodStart
            periodEnd
            status
            total
            hostedInvoiceUrl
            invoicePdf
            created
        }
}`;
