import {
    ReactElement,
    useEffect,
} from 'react';

import {
    SimpleGrid,
    Table,
} from '@mantine/core';

import {useInvoices} from '../hooks/useInvoices';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';
import {useInvoicesSelection} from '../hooks/useInvoicesSelection';
import {IInvoiceTableColumn,} from '../types';
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {

    const {allowedActions} = useVerifiedPermissions()


    const {
        columns,
        visibleItems,
        limit,
        loading,
        totalPages,
        visibleCount,
        fetchRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    } = useInvoices();


    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useInvoicesSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols: IInvoiceTableColumn[] = [];
        if (allowedActions.includes(rbacActions.DeleteInvoice)) {

        cols.push({
                      id: 'select',
                      title: '',
                      render: item => {
                          return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                      },


                  },
        );
    }

    cols = cols.concat(columns);

    return cols;

};


return (
    <SimpleGrid verticalSpacing='xl' m='sm'>
        <SimpleTableBatchAction
            limit={limit}
            handleItemsPerPageChange={handleItemsPerPageChange}
            settings={settings}
            handleFilterByKeyword={handleFilterByKeyword}
            extraTools={extraTools}
        />
        <Table verticalSpacing='sm' striped highlightOnHover>
            <TableHeaderRow
                columns={getColumns() as TableHeaderColumn[]}
                handleSelectAllRows={handleSelectAllRows}
            />
            <TableBody
                items={visibleItems}
                loading={loading}
                selection={selection}
                columns={getColumns() as TableBodyColumn[]}
            />
        </Table>

        <SimpleTablePagination
            totalPages={totalPages}
            visibleCount={visibleCount}
            handlePageChange={handlePageChange}
        />
    </SimpleGrid>
);
}
;
