export const listCustomers = `
   query listCustomers {
       listCustomers {
           items {
              id
              name
              description
              email
              phone
              delinquent
              created
           }
       }
   }
`;

export const getCustomer = `
   query getCustomer($id:ID!) {
       getCustomer(id:$id) {
         id
         name
         description
         email
         phone
         delinquent
         created
       } 
}`;
