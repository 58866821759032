import {
    getInvoice,
    listInvoices
} from './queries';
import {
    createInvoice,
    deleteInvoice,
    sendInvoice,
    updateInvoice,
    voidInvoice,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    CreateInvoice,
    DeleteInvoice,
    GetInvoice,
    Invoice,
    ListInvoices,
    SendInvoice,
    UpdateInvoice,
    VoidInvoice
} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceNotifications.tsx";


const client = generateClient();

export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListInvoices>>({
                                                                              query: listInvoices,
                                                                              variables: {filter: filter},
                                                                          });
        return response.data?.listInvoices.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetInvoice>>({
                                                                            query: getInvoice,
                                                                            variables: {id: id},
                                                                        });
        return response.data?.getInvoice;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateInvoice>>({
                                                                               query: createInvoice,
                                                                               variables: {input: payload},
                                                                           });

        notifySuccess('Invoice created');
        return response.data?.createInvoice;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (id: string, record: Invoice) => {

    console.debug('IN UPDATE', id, record);

    const {
        created,
        updated,
        ...rest
    } = record

    try {
        const response = await client.graphql<GraphQLQuery<UpdateInvoice>>({
                                                                               query: (updateInvoice),
                                                                               variables: {input: rest},
                                                                           });
        notifySuccess('Invoice updated')
        return response.data?.updateInvoice;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteInvoice>>({
                                                                               query: (deleteInvoice),
                                                                               variables: {input: {id: id}},

                                                                           });
        notifySuccess('Invoice deleted')
        return response.data?.deleteInvoice;
    } catch (e) {
        notifyError(e)
    }
};

export const send = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<SendInvoice>>({
                                                                             query: (sendInvoice),
                                                                             variables: {input: {id: id}},

                                                                         });
        notifySuccess('Invoice sent')
        return response.data?.sendInvoice;
    } catch (e) {
        notifyError(e)
    }
};

export const voidRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<VoidInvoice>>({
                                                                             query: (voidInvoice),
                                                                             variables: {input: {id: id}},

                                                                         });
        notifySuccess('Invoice voided')
        return response.data?.voidInvoice;
    } catch (e) {
        notifyError(e)
    }
};
