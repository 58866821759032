import {ReloadButton,} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useParams} from 'react-router-dom';

import {useCards} from '../hooks/useCards.ts';

export const ReloadRecordsButton = () => {

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const {fetchRecords} = useCards(customerId);
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};
