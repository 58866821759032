import {FieldProps} from "formik";

import {
    CardExpiryElement,
    CardExpiryElementProps
} from "@stripe/react-stripe-js";
import {
    Box,
    Stack,
    Text,
    TextInputProps,
    Title
} from "@mantine/core";
import {StripeCardExpiryElementOptions} from "@stripe/stripe-js";

import classes from "./FormikStripeCardExpiry.module.css";

export const FormikStripeCardExpiry = ({
                                           field,
                                           form: {setFieldValue},
                                           ...rest
                                       }: FieldProps & CardExpiryElementProps & TextInputProps) => {

    const options = {
        ...rest.options,
        ...{
            classes: classes,
        }
    } as StripeCardExpiryElementOptions

    return (
        <Stack gap={'md'} my={'xl'}>
            <Title order={4}><Text>{rest.label}</Text></Title>
            <Box className={classes.container}>
                <CardExpiryElement {...field} {...rest}
                                   onChange={value => setFieldValue(field.name, value)}
                                   options={options}


                />
            </Box>
        </Stack>
    );
};

