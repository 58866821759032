import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {AddRecordButton} from '../buttons/AddRecordButton.tsx';
import {DeleteRecordButton} from "../buttons/DeleteRecordButton.tsx";
import {ReloadRecordsButton} from "../buttons/ReloadRecordsButton.tsx";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {SendRecordButton} from "../buttons/SendRecordButton.tsx";
import {SimplePageHeader} from "@flexinet/ui-components";

const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ReloadRecordsButton key='reload'/>];

    if (allowedActions.includes(rbacActions.SendInvoice)) {
        tools.push(<SendRecordButton key='send'/>);
    }

    if (allowedActions.includes(rbacActions.DeleteInvoice)) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes(rbacActions.CreateInvoice)) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={'Invoices'}
            tools={useTools()}
        />
    );
};
