import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';


import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@flexinet/ui-components/style.css'


import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";

import {Elements} from "@stripe/react-stripe-js";
import {
    loadStripe,
    StripeElementsOptions
} from "@stripe/stripe-js";
import {theme} from "./theme.ts";
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";

import {AuthProvider} from "./context/AuthContext.tsx";
import {VerifiedPermissionsProvider} from './providers/VerifiedPermissionsProvider.tsx';
import {actions} from "./config/rbac/actions.ts";
import {CognitoContextProvider} from '@flexinet/ui-components';
import {routesConfig} from "./config/routes.tsx";

const router = createBrowserRouter(routesConfig)


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
    'pk_test_51J9MGPHi5nXRQeoomKKMfIBELXcB97ZeQRZgwZfXQoRwIDpjM5mQk7fv4wKhBq7630bWymsAX7acxtJnjPi4a2gF00a13mABrs'
);

export const App = () => {


    const options = {
        // passing the client secret obtained from the server
        // clientSecret: '{{CLIENT_SECRET}}',
        appearance: {
            theme: 'stripe',
            rules: {
                '.Input': {
                    border: '1px solid #E0E6EB',
                }
            }
        }
    } as StripeElementsOptions;

    return (
        <MantineProvider theme={theme}>
            <Elements stripe={stripePromise} options={options}>
                <AuthProvider>
                    <VerifiedPermissionsProvider actions={actions}>
                        <UserAttributesContextProvider>
                            <CognitoContextProvider>
                                <ModalsProvider>
                                    <Notifications position={'bottom-left'}/>
                                    <RouterProvider router={router}/>
                                </ModalsProvider>
                            </CognitoContextProvider>
                        </UserAttributesContextProvider>
                    </VerifiedPermissionsProvider>
                </AuthProvider>
            </Elements>
        </MantineProvider>
    );
}

