import {ReloadButton,} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {usePrices} from '../hooks/usePrices.ts';

export const ReloadRecordsButton = () => {

    const {fetchRecords} = usePrices();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords({})}/>
        </Box>
    );
};
