import {
    deleteCard,
    fetchCards,
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector,
} from '../selectors';

import {columns} from '../tables/columns';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';

export const useCards = (customerId: string) => {

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const dispatch = useAppDispatch();

    const selection = useAppSelector((state) => selectionSelector(state));

    const {
        items,
        visibleItems,
    } =
        useAppSelector((state) => visibilitySelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );


    // FETCH
    const fetchRecords = () => {
        dispatch(fetchCards(customerId));
    };

    const deleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(deleteCard({
                                        customerId,
                                        id: key
                                    }));
            }
        }
    };


    return {
        items,
        visibleItems,
        loading,
        columns,
        fetchRecords,
        deleteRecords,
    };
};
