import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {
    Subscription,
    SubscriptionStatus
} from '../types';
import {StatusBadge} from "../profile/StatusBadge.tsx";
import {CustomerLink} from "../links/CustomerLink.tsx";

export const columns = [
    {
        title: I18n.get('Subscription ID'),
        width: '1fr',
        render: (item: Subscription) => <NavLink to={`/subscriptions/${item.id}`}>{item.id}</NavLink>,
    },
    {
        title: I18n.get('Customer'),
        width: '1fr',
        render: (item: Subscription) => <CustomerLink customer={item.customer as string}/>,
    },
    {
        title: I18n.get('Start Date'),
        width: '1fr',
        render: (item: Subscription) => formatTimestamp(item.startDate * 1000),
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: Subscription) => <StatusBadge status={item.status as SubscriptionStatus}/>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Subscription) => formatTimestamp(item.created * 1000),
    },
];
