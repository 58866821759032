import {Badge} from "@mantine/core";

export const PaymentStatusBadge = ({status}: { status: boolean }) => {
    const colors = {
        'true': '#007bff',
        'false': '#6c757d',
    } as { [key: string]: string }

    return <Badge color={colors[status.toString()]}>{status ? 'yes' : 'no'}</Badge>;
};

