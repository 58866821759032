import {Price,} from '../types';
import {Stack} from '@mantine/core';
import {NavLink} from 'react-router-dom';
import {PlatformPropertyCard} from "@flexinet/ui-components";


export const Profile = ({record}: { record: Price }) => {
    console.debug(record)

    const properties = [
        {
            label: 'ID',
            value: record.id as string,
            span: 12,
        }, {
            label: 'Product',
            value: <NavLink to={`/products/${record.product}`}>{record.product}</NavLink>,
            span: 12,
        }, {
            label: 'Unit Amount',
            value: record.unit_amount as string,
            span: 12,
        }, {
            label: 'Currency',
            value: record.currency as string,
            span: 12,
        }, {
            label: 'Nickname',
            value: record.nickname as string,
            span: 12,
        }, {
            label: 'Billing Scheme',
            value: record.billing_scheme as string,
            span: 12,
        }, {
            label: 'Recurring Interval',
            value: record.recurring?.interval as string,
            span: 12,
        }, {
            label: 'Recurring Interval Count',
            value: record.recurring?.interval_count,
            span: 12,
        },];

    const properties2 = [
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }]

    return (
        <Stack gap={'xl'}>
            <PlatformPropertyCard properties={properties}/>
            <PlatformPropertyCard properties={properties2}/>
        </Stack>
    );


};
