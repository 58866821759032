import {I18n} from '@aws-amplify/core';

import {FlexiAddButton,} from '@flexinet/ui-components';

import {useNavigate} from 'react-router-dom';

export const AddRecordButton = ({
                                    buttonText = 'Add Price',
                                    size = 'sm',
                                    onClick,
                                }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('add');
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};
