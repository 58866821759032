import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {useInvoices} from '../hooks/useInvoices';
import {useInvoicesSelection} from "../hooks/useInvoicesSelection.ts";

export const DeleteRecordButton = () => {

    const {deleteRecords} = useInvoices();

    const {
        selection,
        handleClearSelection,
    } = useInvoicesSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};
