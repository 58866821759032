export const listSubscriptions = `
   query listSubscriptions {
       listSubscriptions {
           items {
              id
              created
              customer
              currency
              description
              items {
                id
                price {
                  id
                  product
                }
                quantity
              }
              status
              startDate
           }
       }
   }
`;

export const getSubscription = `
   query getSubscription($id:ID!) {
       getSubscription(id:$id) {
            id
            created
            customer
            currency
            description
            items {
                id
                price {
                  id
                  product
                }
                quantity
            }
            status
            startDate
       } 
}`;
