import {
    useNavigate,
    useParams
} from 'react-router-dom';
import {useToggle} from '@mantine/hooks';


import {
    AiOutlineClose,
    AiOutlineCreditCard,
    AiOutlineDownload,
    AiOutlineEdit,
    AiOutlineMail,
    AiOutlineProfile
} from 'react-icons/ai';
import {IconContext} from 'react-icons';
import {
    FlexiButton,
    FlexiCancelButton
} from "@flexinet/ui-components";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {useInvoice} from "./useInvoice.ts";

export const useEditPageTools = () => {

        const {id: invoiceId} = useParams()

        const {
            record,
            handleSend,
            handleVoid
        } = useInvoice(invoiceId);


        const navigate = useNavigate();

        const {allowedActions} = useVerifiedPermissions()

        const [mode, toggle] = useToggle(['view', 'edit']);

        const tools = [];

        if (allowedActions.includes(rbacActions.UpdateInvoice)) {
            tools.push(<FlexiButton key={'download'}
                                    onClick={() => {
                                        window.open(record.invoicePdf);
                                    }}
                                    leftSection={<AiOutlineDownload/>}
                                    disabled={!record.invoicePdf}
            >Download</FlexiButton>);
        }

        if (allowedActions.includes(rbacActions.UpdateInvoice)) {
            tools.push(<FlexiButton key={'pay'}
                                    onClick={() => {
                                        window.open(record.hostedInvoiceUrl);
                                    }}
                                    leftSection={<AiOutlineCreditCard/>}
                                    disabled={record.status !== 'open'}
            >Pay</FlexiButton>);
        }

        if (allowedActions.includes(rbacActions.UpdateInvoice)) {
            tools.push(<FlexiButton key={'send'}
                                    onClick={() => {
                                        handleSend();
                                        navigate(`/invoices`)
                                    }}
                                    leftSection={<AiOutlineMail/>}
            >Send</FlexiButton>);
        }

        if (allowedActions.includes(rbacActions.UpdateInvoice)) {
            tools.push(<FlexiButton key={'void'}
                                    onClick={() => {
                                        handleVoid();
                                        navigate(`/invoices`)
                                    }}
                                    leftSection={<AiOutlineClose/>}
                                    disabled={record.status !== 'open'}
            >Void</FlexiButton>);
        }

        if (allowedActions.includes(rbacActions.UpdateInvoice)) {

            if (mode === 'edit') {
                tools.push(<FlexiButton key={'profile'}
                                        leftSection={<IconContext.Provider value={{size: '24px'}}>
                                            <AiOutlineProfile/></IconContext.Provider>}
                                        onClick={() => toggle()}>Profile</FlexiButton>);
            } else {
                tools.push(<FlexiButton leftSection={<AiOutlineEdit/>} variant={'filled'} key={'edit'}
                                        onClick={() => toggle()}>Edit</FlexiButton>);
            }

        }

        tools.push(<FlexiCancelButton key={'close'}
                                      onClick={() => navigate(`/invoices`)}>Close</FlexiCancelButton>);


        return {
            mode,
            tools,
        };

    }
;
