import {NavLink} from "react-router-dom";

export const CustomerLink = (
    {
        customerId,
        customerName,
        customerEmail
    }: {
        customerId: string,
        customerName: string, customerEmail: string
    }) => {

    return <NavLink to={`/customers/${customerId}`}>{customerName} [ {customerEmail} ]</NavLink>

}