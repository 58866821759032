import {
    setPage,
    setPageSize,
} from '../reducers';

import {visibilitySelector,} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';

export const useCardsPaging = (customerId: string) => {

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const dispatch = useAppDispatch();

    const {
        limit,
        page,
        totalPages,
        visibleCount,
    } =
        useAppSelector((state) => visibilitySelector(state));


    const handleItemsPerPageChange = (pageSize: string | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };

    return {
        limit,
        page,
        totalPages,
        visibleCount,
        handleItemsPerPageChange,
        handlePageChange,
    };
};
