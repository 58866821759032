export const listInvoices = `
   query listInvoices {
       listInvoices {
           items {
              id
              amountDue
              amountPaid
              currency
              customer
              customerName
              customerEmail
              description
              number
              paid
              periodStart
              periodEnd
              status
              total
              hostedInvoiceUrl
              invoicePdf
              created
           }
       }
   }
`;


export const getInvoice = `
   query getInvoice($id:ID!) {
       getInvoice(id:$id) {
            id
            amountDue
            amountPaid
            currency
            customer
            customerName
            customerEmail
            description
            number
            paid
            periodStart
            periodEnd
            status
            total
            hostedInvoiceUrl
            invoicePdf
            created
       } 
}`;
