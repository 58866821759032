import {
    setPage,
    setPageSize,
} from '../reducers';

import {
    recordsSelector,
    visibilitySelector,
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';

export const usePricesPaging = () => {

    const dispatch = useAppDispatch();

    const {
        limit,
        page,
        totalPages,
        visibleCount,
    } =
        useAppSelector((state) => visibilitySelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );


    const handleItemsPerPageChange = (pageSize: string | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };

    return {
        limit,
        loading,
        page,
        totalPages,
        visibleCount,
        handleItemsPerPageChange,
        handlePageChange,
    };
};
