import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {useProducts} from '../hooks/useProducts';
import {useProductsSelection} from "../hooks/useProductsSelection.ts";


export const DeleteRecordButton = () => {

    const {deleteRecords} = useProducts();

    const {
        selection,
        handleClearSelection,
    } = useProductsSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};
