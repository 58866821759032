import {FlexiAddButton,} from '@flexinet/ui-components';

import {Title,} from '@mantine/core';

import {useParams} from 'react-router-dom';
import {useCustomerInvoiceItem} from "../hooks/useCustomerInvoiceItem.ts";


import {openModal,} from '@mantine/modals';


import {RecordForm} from "../forms/RecordForm.tsx";


export const AddRecordButton = () => {


    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter');
    }

    const {createRecord} = useCustomerInvoiceItem(customerId);


    const onClickHandler = () => openModal({
                                               title: <Title order={3}>Add Customer Invoice Item</Title>,
                                               size: 'xl',
                                               children: (
                                                   <RecordForm
                                                       handleSubmit={(values) => {
                                                           createRecord(values);
                                                           //closeAllModals();
                                                       }}
                                                       record={{
                                                           customer: customerId,
                                                       }}
                                                   />
                                               ),
                                           });


    return (
        <FlexiAddButton onClick={onClickHandler}>Add Customer Invoice Item</FlexiAddButton>
    );
};
