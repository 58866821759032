import {
    Subscription,
    SubscriptionStatus,
} from '../types';
import {Stack} from '@mantine/core';
import {PlatformPropertyCard} from '@flexinet/ui-components';
import {CustomerLink} from "../links/CustomerLink.tsx";
import {StatusBadge} from "./StatusBadge.tsx";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";


export const Profile = ({record}: { record: Subscription }) => {

    const properties = [
        {
            label: 'ID',
            value: record.id as string,
            span: 12,
        }, {
            label: 'Customer',
            value: <CustomerLink customer={record.customer as string}/>,
            span: 12
        }, {
            label: 'Start Date',
            value: formatTimestamp(record.startDate * 1000),
            span: 12,
        }, {
            label: 'Status',
            value: <StatusBadge status={record.status as SubscriptionStatus}/>,
            span: 12,
        }, {
            label: 'Description',
            value: record.description as string,
            span: 12,
        }];

    const properties2 = [
        {
            label: 'Created',
            value: record.created,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated,
            formatAs: 'Timestamp',
            span: 12,
        }];

    return (
        <Stack gap={'xl'}>
            <PlatformPropertyCard properties={properties}/>
            <PlatformPropertyCard properties={properties2}/>
        </Stack>
    );


};


