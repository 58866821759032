import {useInvoices} from '../hooks/useInvoices';
import {useInvoicesSelection} from "../hooks/useInvoicesSelection.ts";
import {Button} from "@mantine/core";
import {AiOutlineMail} from "react-icons/ai";

export const SendRecordButton = () => {

    const {sendInvoices} = useInvoices();

    const {
        selection,
        handleClearSelection,
    } = useInvoicesSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<Button disabled={disabled}
                    onClick={() => {
                        sendInvoices()
                        handleClearSelection()
                    }}
                    leftSection={<AiOutlineMail/>}
    >Send</Button>);
};
