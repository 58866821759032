import {ReloadButton,} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useCustomers} from '../hooks/useCustomers.ts';

export const ReloadRecordsButton = () => {

    const {fetchRecords} = useCustomers();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};
