import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {useParams} from 'react-router-dom';

import {useCards} from '../hooks/useCards.ts';
import {useCardsSelection} from "../hooks/useCardsSelection.ts";


export const DeleteRecordButton = () => {

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const {deleteRecords} = useCards(customerId);

    const {
        selection,
        handleClearSelection,
    } = useCardsSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};
