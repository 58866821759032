import {Stack,} from '@mantine/core';
import {Customer,} from '../types';
import {PlatformPropertyCard} from '@flexinet/ui-components';
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";


export const Profile = ({record}: { record: Customer }) => {

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        }, {
            label: 'Description',
            value: record.description as string,
            span: 12,
        }, {
            label: 'Email Address',
            value: record.email as string,
            span: 12,
        }, {
            label: 'Phone Number',
            value: record.phone as string,
            span: 12,
        }];

    const properties2 = [
        {
            label: 'Created',
            value: formatTimestamp(record.created as unknown as number * 1000),
            span: 12,
        }];

    return (
        <Stack gap={'xl'}>
            <PlatformPropertyCard properties={properties}/>
            <PlatformPropertyCard properties={properties2}/>
        </Stack>
    );


};
