import {
    deleteInvoice,
    fetchInvoice,
    saveInvoice,
    sendInvoice,
    updateInvoice,
    voidInvoice,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Invoice} from '../types';

export const useInvoice = (invoiceId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, invoiceId ? invoiceId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof invoiceId === 'undefined') {
            throw new Error('invoiceId is required parameter');
        }
        dispatch(fetchInvoice(invoiceId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Invoice) => {
        dispatch(saveInvoice(values));
    };

    const updateRecord = (values: Invoice) => {
        if (typeof invoiceId === 'undefined') {
            throw new Error('invoiceId is required parameter');
        }

        dispatch(updateInvoice({
                                   id: invoiceId,
                                   record: values,
                               }));
    };

    const deleteRecord = () => {
        if (typeof invoiceId === 'undefined') {
            throw new Error('invoiceId is required parameter');
        }
        dispatch(deleteInvoice(invoiceId));
    };

    const handleSend = () => {
        if (typeof invoiceId === 'undefined') {
            throw new Error('invoiceId is required parameter');
        }
        dispatch(sendInvoice(invoiceId));
    };

    const handleVoid = () => {
        if (typeof invoiceId === 'undefined') {
            throw new Error('invoiceId is required parameter');
        }
        dispatch(voidInvoice(invoiceId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
        handleSend,
        handleVoid
    };
};
