import {
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import {
    SimpleGrid,
    Table,
} from '@mantine/core';

import {useCustomerInvoiceItems} from '../hooks/useCustomerInvoiceItems.ts';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';
import {useCustomerInvoiceItemsSelection} from '../hooks/useCustomerInvoiceItemsSelection.ts';
import {IInvoiceItemTableColumn,} from '../types';
import {useParams} from "react-router-dom";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {

    const {allowedActions} = useVerifiedPermissions()

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }


    const {
        columns,
        visibleItems,
        limit,
        loading,
        totalPages,
        visibleCount,
        fetchRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    } = useCustomerInvoiceItems(customerId);


    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useCustomerInvoiceItemsSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols: IInvoiceItemTableColumn[] = [];
        if (allowedActions.includes(rbacActions.DeleteInvoiceItem)) {

            cols.push({
                          id: 'select',
                          title: '',
                          render: item => {
                              return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                          },


                      },
            );
        }

        cols = cols.concat(columns);

        return cols;

    };

    const tableHeaderColumns = useMemo(() => {
        return getColumns() as TableHeaderColumn[]
    }, [])


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={tableHeaderColumns}
                    // columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
