import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {useParams} from 'react-router-dom';

import {useInvoiceItems} from '../hooks/useInvoiceItems.ts';
import {useInvoiceItemsSelection} from "../hooks/useInvoiceItemsSelection.ts";


export const DeleteRecordButton = () => {

    const {id: invoiceId} = useParams();

    if (typeof invoiceId === 'undefined') {
        throw new Error('invoiceId is required parameter')
    }

    const {deleteRecords} = useInvoiceItems(invoiceId);

    const {
        selection,
        handleClearSelection,
    } = useInvoiceItemsSelection(invoiceId);

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};
