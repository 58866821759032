import {ITableColumn} from '../../types';
import {ReactNode} from 'react';

export type InvoiceDimension = { name: string, value: string }

export enum CollectionMethod {
    charge_automatically = 'charge_automatically',
    send_invoice = 'send_invoice'
}

export type Invoice = {
    id: string,
    amountDue: number,
    amountPaid: number,
    currency: string,
    customer: string,
    customerEmail: string,
    customerName: string,
    description: string,
    number: string,
    paid: boolean,
    periodStart: string,
    periodEnd: string,
    status: string,
    total: number,
    collectionMethod: CollectionMethod,
    daysUntilDue: number,
    hostedInvoiceUrl?: string
    invoicePdf?: string
    created: string,
    updated?: string,
}


export interface IInvoiceTableColumn extends ITableColumn {
    render?: (item: Invoice) => ReactNode;
}


export type ListInvoices = {
    listInvoices: { items: Invoice[] }
}

export type GetInvoice = {
    getInvoice: Invoice
}

export type CreateInvoice = {
    createInvoice: Invoice
}

export type UpdateInvoice = {
    updateInvoice: Invoice
}

export type DeleteInvoice = {
    deleteInvoice: string
}

export type SendInvoice = {
    sendInvoice: Invoice
}

export type VoidInvoice = {
    voidInvoice: Invoice
}