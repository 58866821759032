import {Badge} from "@mantine/core";

export const StatusBadge = ({status}: { status: string }) => {
    const colors = {
        'incomplete': '#007bff',
        'incomplete_expired': '#6c757d',
        'trialing': '#ffc107',
        'past_due': '#dc3545',
        'canceled': '#6c757d',
        'unpaid': '#ffc107',
        'active': '#28a745',
        'paused': '#6c757d',
    } as { [key: string]: string }

    return <Badge color={colors[status]}>{status}</Badge>;
};

