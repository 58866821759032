import {Product,} from '../types';
import {Stack} from '@mantine/core';
import {PlatformPropertyCard} from '@flexinet/ui-components';

export const Profile = ({record}: { record: Product }) => {

    const properties = [
        {
            label: 'Product ID',
            value: record.id as string,
            span: 12,
        },
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        },
        {
            label: 'Description',
            value: record.description as string,
            span: 12,
        }];

    const properties2 = [
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }];


    return (
        <Stack gap={'xl'}>
            <PlatformPropertyCard properties={properties}/>
            <PlatformPropertyCard properties={properties2}/>
        </Stack>
    );


};
