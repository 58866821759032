import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {usePrices} from '../hooks/usePrices.ts';
import {usePricesSelection} from "../hooks/usePricesSelection.ts";


export const DeleteRecordButton = () => {

    const {deleteRecords} = usePrices();

    const {
        selection,
        handleClearSelection,
    } = usePricesSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};
