import {
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import {
    SimpleGrid,
    Table,
} from '@mantine/core';

import {useSubscriptionItems} from '../hooks/useSubscriptionItems.ts';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';
import {useSubscriptionItemsSelection} from '../hooks/useSubscriptionItemsSelection.ts';
import {
    ISubscriptionItemTableColumn,
    SubscriptionItem,
} from '../types';
import {useParams} from "react-router-dom";
import {I18n} from "@aws-amplify/core";
import {ModalContextProvider} from "../../../context/ModalContext.tsx";
import {RecordForm} from "../forms/RecordForm.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
        extraTools?: ReactElement[],
        settings?: { label: string, onClick: () => void }[]
    }) => {

        const {allowedActions} = useVerifiedPermissions()

        const {id: customerId} = useParams();

        if (typeof customerId === 'undefined') {
            throw new Error('customerId is required parameter')
        }

        const {
            columns,
            visibleItems,
            limit,
            loading,
            totalPages,
            visibleCount,
            fetchRecords,
            handleFilterByKeyword,
            handleItemsPerPageChange,
            handlePageChange,
        } = useSubscriptionItems(customerId);


        const {
            selection,
            handleRowSelection,
            handleSelectAllRows,
        } = useSubscriptionItemsSelection();

        useEffect(() => {
            fetchRecords();
        }, []);


        const getColumns = () => {
                let cols: ISubscriptionItemTableColumn[] = [];
                if (allowedActions.includes(rbacActions.DeleteSubscriptionItem)) {

                    cols.push({
                                  id: 'select',
                                  title: '',
                                  render: item => {
                                      return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                                  },


                              },
                    );
                }

                cols = cols.concat(columns);


                if (allowedActions.includes(rbacActions.UpdateSubscriptionItem)) {
                    cols.push({
                                  title: I18n.get('Name'),
                                  render: (item) => {
                                      //const { updateRecord } = useUserApplication(item.id);
                                      const updateRecord = (item: SubscriptionItem) => {
                                          console.debug(item);
                                      };

                                      return (
                                          <ModalContextProvider
                                              title='User Application'
                                              buttonOptions={{
                                                  variant: 'ghost',
                                                  label: 'Edit',
                                                  iconType: 'edit',
                                                  size: 'small',
                                              }}
                                          >
                                              <RecordForm
                                                  record={item}
                                                  handleSubmit={updateRecord}
                                              />
                                          </ModalContextProvider>
                                      );
                                  },
                              },
                    );
                }


                return cols;

            }
        ;

        const tableHeaderColumns = useMemo(() => {
            return getColumns() as TableHeaderColumn[]
        }, [])


        return (
            <SimpleGrid verticalSpacing='xl' m='sm'>
                <SimpleTableBatchAction
                    limit={limit}
                    handleItemsPerPageChange={handleItemsPerPageChange}
                    settings={settings}
                    handleFilterByKeyword={handleFilterByKeyword}
                    extraTools={extraTools}
                />
                <Table verticalSpacing='sm' striped highlightOnHover>
                    <TableHeaderRow
                        columns={tableHeaderColumns}
                        // columns={getColumns() as TableHeaderColumn[]}
                        handleSelectAllRows={handleSelectAllRows}
                    />
                    <TableBody
                        items={visibleItems}
                        loading={loading}
                        selection={selection}
                        columns={getColumns() as TableBodyColumn[]}
                    />
                </Table>

                <SimpleTablePagination
                    totalPages={totalPages}
                    visibleCount={visibleCount}
                    handlePageChange={handlePageChange}
                />
            </SimpleGrid>
        );
    }
;
