import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Invoice} from '../types';
import {StatusBadge} from "../profile/StatusBadge.tsx";

export const columns = [
    {
        title: I18n.get('Amount'),
        width: '1fr',
        render: (item: Invoice) => <NavLink
            to={`/invoices/${item.id}`}>{item.amountDue / 100} {item.currency}</NavLink>,
    },
    {
        title: I18n.get('Customer'),
        width: '1fr',
        render: (item: Invoice) => item.customerName ? <NavLink
            to={`/customers/${item.customer}`}>{item.customerName}</NavLink> : <NavLink
            to={`/customers/${item.customer}`}>{item.customer}</NavLink>,
    },
    {
        title: I18n.get('Number'),
        width: '1fr',
        render: (item: Invoice) => item.number,
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: Invoice) => <StatusBadge status={item.status}/>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Invoice) => formatTimestamp(item.created as unknown as number * 1000),
    },
];
