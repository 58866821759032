import {FieldProps} from "formik";

import {
    CardNumberElement,
    CardNumberElementProps
} from "@stripe/react-stripe-js";
import {
    Box,
    Stack,
    Text,
    TextInputProps,
    Title
} from "@mantine/core";
import {StripeCardCvcElementOptions} from "@stripe/stripe-js";

import classes from "./FormikStripeCardNumber.module.css";

export const FormikStripeCardNumber = ({
                                           field,
                                           form: {setFieldValue},
                                           ...rest
                                       }: FieldProps & CardNumberElementProps & TextInputProps) => {

    const options = {
        classes: classes
    } as StripeCardCvcElementOptions

    return (
        <Stack gap={'md'} my={'xl'}>
            <Title order={4}><Text>{rest.label}:</Text></Title>
            <Box className={classes.container}>
                <CardNumberElement {...field} {...rest}
                                   onChange={value => setFieldValue(field.name, value)}
                                   options={options}

                />
            </Box>
        </Stack>
    );
};

