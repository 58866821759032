import {
    Grid,
    Stack,
} from '@mantine/core';
import {CustomersWidget} from './dashboard/CustomersWidget.tsx';
import {SubscriptionsWidget} from "./dashboard/SubscriptionsWidget.tsx";
import {PricesWidget} from "./dashboard/PricesWidget.tsx";
import {InvoicesWidget} from "./dashboard/InvoicesWidget.tsx";
import {ProductsWidget} from "./dashboard/ProductsWidget.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from '../../../config/rbac/actions.ts';

export const PortalDashboard = () => {

    const {allowedActions} = useVerifiedPermissions()

    return (
        <Grid mx={'10%'}>
            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                    {allowedActions.includes(rbacActions.ListCustomers) ? <CustomersWidget/> : null}
                    {allowedActions.includes(rbacActions.ListInvoices) ? <InvoicesWidget/> : null}
                    {allowedActions.includes(rbacActions.ListProducts) ? <ProductsWidget/> : null}
                </Stack>
            </Grid.Col>

            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                    {allowedActions.includes(rbacActions.ListSubscriptions) ? <SubscriptionsWidget/> : null}
                    {allowedActions.includes(rbacActions.ListPrices) ? <PricesWidget/> : null}
                </Stack>
            </Grid.Col>
        </Grid>
    );
};
