import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {SubscriptionItem} from '../types';
import {FormWithButtons} from "@flexinet/ui-components";
import {stage} from "../../../config/stage.ts";
import {useNavigate} from "react-router-dom";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<SubscriptionItem, 'id'>,
    handleSubmit: (values: SubscriptionItem) => void
}) => {

    const navigate = useNavigate()


    const onSubmit = async (values: SubscriptionItem) => {

        handleSubmit(values);
        navigate(`/customers/${record.customer}`);

    };

    const onCancel = () => {
        navigate(`/customers/${record.customer}`);
    };


    let initialValues = {
        currency: 'usd',
        country: 'US'
    } as SubscriptionItem;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting} handleCancel={onCancel}
                                 debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent/>
                </FormWithButtons>
            )}


        </Formik>
    );
};
