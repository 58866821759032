import {I18n} from '@aws-amplify/core';

import {SimplePageHeader,} from '@flexinet/ui-components';
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from '../../../config/rbac/actions.ts';
import {ReloadRecordsButton} from "../buttons/ReloadRecordsButton.tsx";
import {DeleteRecordButton} from "../buttons/DeleteRecordButton.tsx";
import {AddRecordButton} from "../buttons/AddRecordButton.tsx";


const useTools = () => {

        const {allowedActions} = useVerifiedPermissions()

        const tools = [<ReloadRecordsButton key='reload'/>];

        if (allowedActions.includes(rbacActions.DeleteProduct)) {
            tools.push(<DeleteRecordButton key='delete'/>);
        }

        if (allowedActions.includes(rbacActions.CreateProduct)) {
            tools.push(<AddRecordButton key='add'/>);
        }


        return tools;
    }
;

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Products')}
            tools={useTools()}
        />
    );
};
