import {FieldProps} from "formik";

import {
    AddressElement,
    AddressElementProps
} from "@stripe/react-stripe-js";
import {
    Stack,
    TextInputProps
} from "@mantine/core";
import {StripeAddressElementOptions} from "@stripe/stripe-js";


export const FormikStripeAddress = ({
                                        field,
                                        form: {setFieldValue},
                                        ...rest
                                    }: FieldProps & AddressElementProps & TextInputProps) => {

    const options = {
        mode: 'billing',
        fields: {
            phone: 'always',
            postalCode: 'always',
        },
    } as StripeAddressElementOptions;

    return (
        <Stack>
            <AddressElement {...field} {...rest}
                            onChange={value => setFieldValue(field.name, value)}
                            options={options}
            />
        </Stack>
    );
};

