import {RecordForm} from '../forms/RecordForm';


import {useBankAccount} from '../hooks/useBankAccount.ts';
import {
    FormPageLayout,
    SimplePageContentWrapper
} from "@flexinet/ui-components";
import {useParams} from "react-router-dom";
import {AddPageNavigation} from "../navigation/BreadcrumbsNavigation.tsx";


export const AddPage = () => {

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const {
        record,
        createRecord,
    } = useBankAccount(customerId);

    return (
        <FormPageLayout
            title='Add Bank Account'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper>
                <RecordForm record={record} handleSubmit={createRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
