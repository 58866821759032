import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from "./pages/EditPage.tsx";

import {AddPage as AddBankAccountPage} from '../bank-accounts/pages/AddPage';
import {EditPage as EditBankAccountPage} from '../bank-accounts/pages/EditPage.tsx';
import {AddPage as AddCardPage} from '../cards/pages/AddPage';
import {EditPage as EditCardPage} from '../cards/pages/EditPage.tsx';
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../config/rbac/actions.ts";


export const CustomersLayout = () => {

    const {allowedActions} = useVerifiedPermissions()


    return (
        <Routes>
            <Route
                path='/'
                element={
                    allowedActions.includes(rbacActions.ListCustomers) ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            />
            <Route
                path='add'
                element={
                    allowedActions.includes(rbacActions.CreateCustomer) ? (
                        <AddPage/>
                    ) : (
                        <Navigate to='/customers'/>
                    )
                }
            />
            <Route
                path=':id'
                element={
                    allowedActions.includes(rbacActions.GetCustomer) ? (
                        <EditPage/>
                    ) : (
                        <Navigate to='/customers'/>
                    )
                }

            >
            </Route>


            <Route
                path=':id/bank-accounts/add'
                element={
                    allowedActions.includes(rbacActions.CreateBankAccount) ? (
                        <AddBankAccountPage/>
                    ) : (
                        <Navigate to='/customers/:id'/>
                    )
                }
            />

            <Route
                path=':id/bank-accounts/:bankAccountId'
                element={
                    allowedActions.includes(rbacActions.GetBankAccount) ? (
                        <EditBankAccountPage/>
                    ) : (
                        <Navigate to='/customers/:id'/>
                    )
                }
            />

            <Route
                path=':id/cards/add'
                element={
                    allowedActions.includes(rbacActions.CreateCard) ? (
                        <AddCardPage/>
                    ) : (
                        <Navigate to='/customers/:id'/>
                    )
                }
            />

            <Route
                path=':id/cards/:cardId'
                element={
                    allowedActions.includes(rbacActions.GetCard) ? (
                        <EditCardPage/>
                    ) : (
                        <Navigate to='/customers/:id'/>
                    )
                }
            />

        </Routes>
    );
};
