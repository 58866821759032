import {Badge} from "@mantine/core";

export const StatusBadge = ({active}: { active: boolean }) => {
    const colors = {
        'true': '#007bff',
        'false': '#6c757d',
    } as { [key: string]: string }

    return <Badge color={colors[active.toString()]}>{active ? 'yes' : 'no'}</Badge>;
};

