import {Badge} from "@mantine/core";

export const StatusBadge = ({status}: { status: string }) => {
    const colors = {
        'draft': '#6c757d',
        'open': '#007bff',
        'paid': '#28a745',
        'uncollectible': '#dc3545',
        'void': '#ffc107',
    } as { [key: string]: string }

    return <Badge color={colors[status]}>{status}</Badge>;
};

