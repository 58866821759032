import {
    Field,
    FormikProps
} from 'formik';

import {Stack} from '@mantine/core';
import {CustomersAutocomplete} from "../../customers/inputs/CustomersAutocomplete.tsx";
import {
    FormikNumberInput,
    FormikRadioGroup,
    FormikSelect,
    PlatformFieldset
} from "@flexinet/ui-components";
import {
    CollectionMethod,
    Invoice
} from "../types.ts";

export const FormComponent = ({values}: FormikProps<Invoice>) => {


    return (
        <Stack>
            <PlatformFieldset legend={'Invoice Details'}>
                <Field
                    name='customer'
                    label='Customer'
                    description='Please enter the customer'
                    component={CustomersAutocomplete}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='currency'
                    label='Currency'
                    description='Please enter the currency'
                    component={FormikSelect}
                    data={[
                        {
                            label: 'USD',
                            value: 'usd'
                        },
                        {
                            label: 'EUR',
                            value: 'eur'
                        },
                        {
                            label: 'GBP',
                            value: 'gbp'
                        },
                    ]}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>


            <PlatformFieldset legend={'Payment Collection'}>
                <Field
                    name='collectionMethod'
                    label='Payment Collection'
                    description='Please select method for payment collection'
                    data={[{
                        label: 'Request Payment',
                        value: CollectionMethod.send_invoice
                    }, {
                        label: 'Charge Automatically',
                        value: CollectionMethod.charge_automatically
                    }]}
                    component={FormikRadioGroup}
                    withAsterisk
                    my={'xl'}
                />

                {values.collectionMethod === CollectionMethod.send_invoice &&
                    <Field
                        name='daysUntilDue'
                        label='Days Until Due'
                        description='Please enter number of days untill due'
                        component={FormikNumberInput}
                        withAsterisk
                        my={'xl'}
                    />}

            </PlatformFieldset>
        </Stack>
    );
};
