import {
    clearKeyword,
    setKeyword,
} from '../reducers';

import {visibilitySelector,} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';

export const useBankAccountsSearch = (customerId: string) => {

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const dispatch = useAppDispatch();


    const {
        keyword,
    } =
        useAppSelector((state) => visibilitySelector(state));


    const handleFilterByKeyword = (keyword: string) => {
        if (keyword !== '') {
            dispatch(setKeyword(keyword));
        } else {
            dispatch(clearKeyword());
        }
    };

    return {
        keyword,
        handleFilterByKeyword,
    };
};
