import {
    ReactNode,
    useEffect,
    useState,
} from 'react';
import {VerifiedPermissionsContext} from '../context/VerifiedPermissionsContext';
import {
    BatchIsAuthorizedWithTokenCommandInput,
    BatchIsAuthorizedWithTokenInputItem,
    BatchIsAuthorizedWithTokenOutputItem,
} from '@aws-sdk/client-verifiedpermissions';
import {runBatchAuthorization} from '../services/avp/AmazonVerifiedPermissionsService.ts';
import {fetchAuthSession} from "aws-amplify/auth";
import permissions from "../config/permissions.ts";


export const VerifiedPermissionsProvider = (
    {
        actions,
        children,
    }: {
        actions: string[],
        children?: ReactNode
    }) => {

    const maxItems = 30;
    const [authorizing, setAuthorizing] = useState(true);
    const [allowedActions, setAllowedActions] = useState<string[]>([]);

    const actionsSet = new Set(actions);
    const uniqueActions = Array.from(actionsSet);
    const actionGroups = [] as string[][];
    for (let i = 0; i < uniqueActions.length; i += maxItems) {
        actionGroups.push(uniqueActions.slice(i, i + maxItems));
    }

    // console.debug('ACTION GROUPS', actionGroups);

    useEffect(() => {

        const getDecisions = async (group: string[]) => {

            const {
                idToken
            } = (await fetchAuthSession()).tokens ?? {};

            if (typeof idToken === 'undefined') {
                throw new Error('idToken is required parameter');
            }

            const requests = [] as BatchIsAuthorizedWithTokenInputItem[];
            group.forEach((action: string) => {
                requests.push({
                                  action: { // ActionIdentifier
                                      actionType: 'Action', // required
                                      actionId: `${action}`, // required
                                  },
                              });
            });


            const input = { // IsAuthorizedWithTokenInput
                policyStoreId: permissions.policyStoreId,  // required
                identityToken: idToken.toString(),
                requests: requests,
            } as BatchIsAuthorizedWithTokenCommandInput;

            return await runBatchAuthorization(input);
        };

        const promises = [] as Promise<any>[];
        actionGroups.forEach(async (group) => {
            promises.push(getDecisions(group));
        });

        Promise.all(promises)
               .then(resultGroups => {
                   const allowed = [] as string[];
                   resultGroups.forEach((resultGroup) => {
                       resultGroup.forEach((result: BatchIsAuthorizedWithTokenOutputItem) => {
                           if (result.decision === 'ALLOW') {
                               allowed.push(result.request?.action?.actionId as string);
                           }
                       });
                   });
                   setAllowedActions(allowed);
                   setAuthorizing(false);
               });

    }, []);

    // console.debug('ALLOWED ACTIONS', allowedActions);

    return (
        <VerifiedPermissionsContext.Provider
            value={{
                authorizing,
                allowedActions,
            }}
        >
            {children}
        </VerifiedPermissionsContext.Provider>
    );
};
