import {Invoice,} from '../types';
import {
    Box,
    Group,
    Stack
} from '@mantine/core';
import {PlatformPropertyCard} from "@flexinet/ui-components";
import {StatusBadge} from "./StatusBadge.tsx";
import {CustomerLink} from "../links/CustomerLink.tsx";
import {PaymentStatusBadge} from "./PaymentStatusBadge.tsx";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";
import {StripeAmount} from "../../../components/StripeAmount.tsx";


export const Profile = ({record}: { record: Invoice }) => {

    const properties = [
        {
            label: 'Invoice Number',
            value: record.number as string,
            span: 6,
        }, {
            label: 'Status',
            value: <StatusBadge status={record.status}/>,
            span: 6,
        }, {
            label: 'Customer',
            value: <CustomerLink customerId={record.customer} customerName={record.customerName}
                                 customerEmail={record.customerEmail}/>,
            span: 12,
        }, {
            label: 'Period',
            value:
                <Group>
                    <Box>{formatTimestamp(parseInt(record.periodStart) * 1000, 'MMM DD, YYYY')}</Box> - <Box>{formatTimestamp(parseInt(record.periodEnd) * 1000, 'MMM DD, YYYY')}</Box></Group>,
            span: 12,
        }, {
            label: 'Total',
            value: <StripeAmount amount={record.total}/>,
            span: 12,
        }, {
            label: 'Amount Due',
            value: <StripeAmount amount={record.amountDue}/>,
            span: 12,
        }, {
            label: 'Amount Paid',
            value: <StripeAmount amount={record.amountPaid}/>,
            span: 12,
        }, {
            label: 'Paid',
            value: <PaymentStatusBadge status={record.paid}/>,
            span: 12,
        }];

    const properties2 = [
        {
            label: 'ID',
            value: record.id as string,
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }];

    return (
        <Stack gap={'xl'}>
            <PlatformPropertyCard properties={properties}/>
            <PlatformPropertyCard properties={properties2}/>
        </Stack>
    );


};
