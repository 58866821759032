import {FieldProps} from "formik";

import {
    CardCvcElement,
    CardCvcElementProps
} from "@stripe/react-stripe-js";
import {
    Box,
    Stack,
    Text,
    TextInputProps,
    Title
} from "@mantine/core";
import {StripeCardCvcElementOptions} from "@stripe/stripe-js";

import classes from "./FormikStripeCardCvc.module.css";

export const FormikStripeCardCvc = ({
                                        field,
                                        form: {setFieldValue},
                                        ...rest
                                    }: FieldProps & CardCvcElementProps & TextInputProps) => {

    const options = {
        classes: classes
    } as StripeCardCvcElementOptions

    return (
        <Stack gap={'md'} my={'xl'}>
            <Title order={4}><Text>{rest.label}</Text></Title>
            <Box className={classes.container}>
                <CardCvcElement {...field} {...rest}
                                onChange={value => setFieldValue(field.name, value)}
                                options={options}
                />
            </Box>
        </Stack>
    );
};

