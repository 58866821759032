export enum rbacActions {
    ViewDashboard = 'ViewDashboard',

    ListBankAccounts = 'ListBankAccounts',
    GetBankAccount = 'GetBankAccount',
    CreateBankAccount = 'CreateBankAccount',
    UpdateBankAccount = 'UpdateBankAccount',
    DeleteBankAccount = 'DeleteBankAccount',

    ListCards = 'ListCards',
    GetCard = 'GetCard',
    CreateCard = 'CreateCard',
    UpdateCard = 'UpdateCard',
    DeleteCard = 'DeleteCard',

    ListInvoices = 'ListInvoices',
    GetInvoice = 'GetInvoice',
    CreateInvoice = 'CreateInvoice',
    UpdateInvoice = 'UpdateInvoice',
    DeleteInvoice = 'DeleteInvoice',
    SendInvoice = 'SendInvoice',
    VoidInvoice = 'VoidInvoice',

    ListCustomers = 'ListCustomers',
    GetCustomer = 'GetCustomer',
    CreateCustomer = 'CreateCustomer',
    UpdateCustomer = 'UpdateCustomer',
    DeleteCustomer = 'DeleteCustomer',

    ListProducts = 'ListProducts',
    GetProduct = 'GetProduct',
    CreateProduct = 'CreateProduct',
    UpdateProduct = 'UpdateProduct',
    DeleteProduct = 'DeleteProduct',

    ListSubscriptions = 'ListSubscriptions',
    GetSubscription = 'GetSubscription',
    CreateSubscription = 'CreateSubscription',
    UpdateSubscription = 'UpdateSubscription',
    DeleteSubscription = 'DeleteSubscription',

    ListPrices = 'ListPrices',
    GetPrice = 'GetPrice',
    CreatePrice = 'CreatePrice',
    UpdatePrice = 'UpdatePrice',
    DeletePrice = 'DeletePrice',

    ListInvoiceItems = 'ListInvoiceItem',
    GetInvoiceItem = 'GetInvoiceItem',
    CreateInvoiceItem = 'CreateInvoiceItem',
    UpdateInvoiceItem = 'UpdateInvoiceItem',
    DeleteInvoiceItem = 'DeleteInvoiceItem',

    ListSubscriptionItems = 'ListSubscriptionItem',
    GetSubscriptionItem = 'GetSubscriptionItem',
    CreateSubscriptionItem = 'CreateSubscriptionItem',
    UpdateSubscriptionItem = 'UpdateSubscriptionItem',
    DeleteSubscriptionItem = 'DeleteSubscriptionItem',
}

export const actions = Object.values(rbacActions);


export const actionConstants = actions.reduce((acc, action) => {
    acc[action.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase())
              .toUpperCase()] = action;
    return acc;
}, {} as Record<string, string>);