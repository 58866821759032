import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {useParams} from 'react-router-dom';

import {useSubscriptionItems} from '../hooks/useSubscriptionItems.ts';
import {useSubscriptionItemsSelection} from "../hooks/useSubscriptionItemsSelection.ts";


export const DeleteRecordButton = () => {

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const {deleteRecords} = useSubscriptionItems(customerId);

    const {
        selection,
        handleClearSelection,
    } = useSubscriptionItemsSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};
